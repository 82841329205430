@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto&family=Roboto+Mono&display=swap");

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.App,
body,
html {
  height: 100%;
  line-height: 1.8;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
}

.flightDeckLogoFont {
  font-family: "Montserrat", sans-serif;
}

.w3-bar .w3-button {
  padding: 16px;
}

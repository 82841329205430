.hero-device-image {
  max-height: 50vh;
  object-fit: contain;
}

.hero-background-image {
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/assets/hero.jpg");
  padding: 128px 16px;
}

.contactContainer {
  padding: 128px 16px;
}

.contactTopMargin {
  margin-top: 48px;
}

.contactCard {
  height: 300px;
}

.contactCardHeight {
  height: 300px;
}

.contactCardLink {
  text-decoration: none; /* no underline */
}

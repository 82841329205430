.pricingContainer {
  padding: 128px 16px;
}

.pricingTopMargin {
  margin-top: 64px;
  margin: 0 auto;
  max-width: 600px;
}

.pricing-card-shadow {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

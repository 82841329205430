.communityContainer {
  padding: 128px 16px;
}

.communityTopMargin {
  margin-top: 64px;
  min-height: 300px;
  margin: 0 auto;
}

.communityImageWidth {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.cardHeight {
  height: 100%;
}

.cardLink {
  text-decoration: none; /* no underline */
}
